// src/mixins/ConnectionMixin.js
import Swal from "sweetalert2";

export default {
  data() {
    return {
      isOnline: navigator.onLine, // Initial connection status
    };
  },
  methods: {
    checkRoutePermission(frontEndRole) {
      const userData = JSON.parse(localStorage.getItem("userData"));

      console.log(userData);

      if (!userData) {
        localStorage.clear();
        this.$router.push({ name: "Login" });
        return false;
      }

      if (
        this.$route.meta.Role === "Transporter" &&
        userData.businessNature === "Transporter" &&
        frontEndRole === "Transporter"
      ) {
        return true;
      }

      if (
        this.$route.meta.Role === "Ocean Carrier" &&
        userData.businessNature === "Ocean Carrier" &&
        frontEndRole === "Ocean Carrier"
      ) {
        return true;
      }

      if (frontEndRole == "Any-Not-Transporetr-ShippingLine"){
        if (
          this.$route.meta.Role === "Any-Not-Transporetr-ShippingLine" &&
          (userData.businessNature === "Exporter" ||
            userData.businessNature === "CHA" ||
            userData.businessNature === "Freight Forwarder" ||
            userData.businessNature === "Importer")
        ) {
          return true;
        }
      }

      
     if(frontEndRole === "Any-Not-ShippingLine"){
      if (
        this.$route.meta.Role === "Any-Not-ShippingLine" &&
        userData.businessNature != "Ocean Carrier"
      ) {
        return true;
      }
     }

      Swal.fire({
        icon: "error",
        title: "Access Denied",
        text: "You do not have permission to access this page.",
      });
      // this.$router.push({ name: "home" });
      setTimeout(() => {
        window.history.back();
      }, 3000);
      return false;
    },

    checkConnection() {
      if (!this.isOnline) {
        Swal.fire({
          icon: "warning",
          title: "No Internet Connection",
          text: "Please connect your internet connection!",
          confirmButtonText: "Okay",
        });
        return false;
      }
      return true;
    },

    handleOffline() {
      this.isOnline = false;
      Swal.fire({
        icon: "warning",
        title: "No Internet Connection",
        text: "Please connect your internet connection!",
        confirmButtonText: "Okay",
      });
    },
    handleOnline() {
      this.isOnline = true;
      Swal.close();
    },
  },
  mounted() {
    // Listen for online/offline events
    window.addEventListener("offline", this.handleOffline);
    window.addEventListener("online", this.handleOnline);
  },
  beforeUnmount() {
    // Remove event listeners to prevent memory leaks
    window.removeEventListener("offline", this.handleOffline);
    window.removeEventListener("online", this.handleOnline);
  },
};
